import {IMaskInput} from "react-imask";
import React from "react";

export default function PhoneNumberMask(props: any) {
    const { inputRef, onChangePhoneNumber, ...other } = props;

    return (
        <IMaskInput
            {...other}
            mask={[{
                mask: '+{7} (DDD) DDD-DD-DD',
                definitions: {
                    D: /[0-9]/,
                },
                lazy: true
            }]}
            unmask={true}
            inputRef={inputRef}
            onComplete={
                (value: string, mask: string) => {
                    onChangePhoneNumber(value)
                }
            }
        />
    );
}