import Grid from "@mui/material/Grid";
import {Button, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {ChangeEvent, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import LicencePlateMask from "./LicencePlateMask";
import PhoneNumberMask from "./PhoneNumberMask";

export interface LicencePlateInputInterface {
    onRemove: () => void
    onLicencePlateChange: (v: string) => void
    onLicencePlateCountryChange: (v: string) => void
    onLicencePlateAreaChange: (v: number[]) => void
    onLicencePlatePhoneChange: (v: string) => void
    onLicencePlateOwnerChange: (v: string) => void
    onInvalid: (v: boolean) => void
    licencePlate?: string
    licencePlateCountry?: string
    licencePlateArea?: number[]
    isDeleted: boolean
}
export default function LicencePlateInput(props: LicencePlateInputInterface){
    const [licencePlateCountry, setLicencePlateCountry] = useState<string>('rus')
    const [licencePlateAreas, setLicencePlateAreas] = useState<number[]>([])

    const [isInvalid, setInvalid] = useState(true);

    const [createdLicencePlate, setCreatedLicencePlate] = useState<string>('');
    const [createdLicencePlateCountry, setCreatedLicencePlateCountry] = useState<string>('');

    const [createdLicencePlateOwner, setCreatedLicencePlateOwner] = useState<string>('');
    const [createdLicencePlateOwnerPhone, setCreatedLicencePlateOwnerPhone] = useState<string>('');

    const changeArea = (event: SelectChangeEvent<typeof licencePlateAreas>) => {
        const { value } = event.target;
        if (typeof value === typeof []) setLicencePlateAreas(value as [])
        props.onLicencePlateAreaChange(value as []);
    };

    const setCountry = (e: SelectChangeEvent) => {
        setLicencePlateCountry(e.target.value);
        setCreatedLicencePlateCountry(e.target.value !== 'unknown' ? e.target.value : '' );
        props.onLicencePlateCountryChange(e.target.value);
    }

    const setCustomCountry = (e: ChangeEvent<HTMLInputElement>) => {
        setCreatedLicencePlateCountry(e.target.value);
        props.onLicencePlateCountryChange(e.target.value);
    }

    const onRemove = () => {
        props.onRemove();
    }

    useEffect(()=>{
        if (props.licencePlate) setCreatedLicencePlate(props.licencePlate);
    }, [props.licencePlate])

    useEffect(()=>{
        if (props.licencePlateArea) setLicencePlateAreas(props.licencePlateArea);
    }, [props.licencePlateArea])

    useEffect(()=>{
        if (props.licencePlateCountry) {
            if (licencePlateCountry !== 'unknown'){
                setLicencePlateCountry(props.licencePlateCountry);
                setCreatedLicencePlateCountry(props.licencePlateCountry);
            }
        }
    }, [props.licencePlateCountry])

    useEffect(()=>{
        if (createdLicencePlateOwner) props.onLicencePlateOwnerChange(createdLicencePlateOwner);
    }, [createdLicencePlateOwner])

    useEffect(()=>{
        if (createdLicencePlateOwnerPhone) props.onLicencePlatePhoneChange(createdLicencePlateOwnerPhone);
    }, [createdLicencePlateOwnerPhone])

    useEffect(()=>{
        if (props.licencePlateArea) setLicencePlateAreas(props.licencePlateArea);
    }, [props.licencePlateArea])

    useEffect(()=>{
        if (createdLicencePlate.length == 0 || (licencePlateCountry === 'unknown' && createdLicencePlateCountry.length === 0 || createdLicencePlateOwnerPhone.length === 0 || createdLicencePlateOwner.length === 0)){
            props.onInvalid(true);
        } else {
            props.onInvalid(false);
        }
    }, [createdLicencePlate, createdLicencePlateCountry, licencePlateCountry, createdLicencePlateOwner, createdLicencePlateOwnerPhone])

    return (
        <FormControl fullWidth sx={{ mt: 1, mb: 2, display: "flex", flexDirection: "row" }}>
            <div style={{flex: 1}}>
                <Grid container spacing={1}>
                    <Grid container item xs={5} sm={3} md={3} lg={3} xl={3}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="idCountryLicencePlate">Страна</InputLabel>
                            <Select
                                labelId="idCountryLicencePlate"
                                value={licencePlateCountry}
                                label='Страна'
                                required
                                onChange={setCountry}
                            >
                                <MenuItem value={'rus'}>Россия</MenuItem>
                                <MenuItem value={'lv'}>Латвия</MenuItem>
                                <MenuItem value={'fin'}>Финляндия</MenuItem>
                                <MenuItem value={'by'}>Беларусь</MenuItem>
                                <MenuItem value={'uz'}>Узбекистан</MenuItem>
                                <MenuItem value={'ua'}>Украина</MenuItem>
                                <MenuItem value={'kg'}>Киргизия</MenuItem>
                                <MenuItem value={'kz'}>Казахстан</MenuItem>
                                <MenuItem value={'unknown'}>Моей страны нет в списке</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={7} sm={9} md={9} lg={9} xl={9}>
                        <TextField
                            error={createdLicencePlate.length === 0}
                            required
                            fullWidth
                            name="licencePlate"
                            label="Номерной знак"
                            InputProps={{
                                inputComponent: LicencePlateMask,
                                value: createdLicencePlate,
                                inputProps: { licencePlateCountry, onChangeLicencePlate: (v: string) => {
                                        setCreatedLicencePlate(v);
                                        props.onLicencePlateChange(v);
                                    }
                                },
                            }}
                        />
                        {
                            licencePlateCountry === 'unknown' &&
                            <TextField
                                sx={{ mt: 1}}
                                error={createdLicencePlateCountry.length === 0}
                                value={createdLicencePlateCountry}
                                onChange={setCustomCountry}
                                required
                                fullWidth
                                name="licencePlateCustomCountry"
                                label="Укажите страну"
                            />
                        }
                    </Grid>
                </Grid>
                <FormControl fullWidth variant="outlined" sx={{ mt: 1, mb: 1 }}>
                    <InputLabel id="idAreaLicencePlate">Мне нужен доступ во дворы</InputLabel>
                    <Select
                        labelId="idAreaLicencePlate"
                        label='Мне нужен доступ во дворы'
                        multiple
                        fullWidth
                        renderValue={(selected) => selected.join(", ")}
                        value={licencePlateAreas}
                        onChange={changeArea}
                    >
                        <MenuItem value={1}>Двор 1</MenuItem>
                        <MenuItem value={2}>Двор 2</MenuItem>
                        <MenuItem value={3}>Двор 3</MenuItem>
                        <MenuItem value={5}>Двор 4-5</MenuItem>
                        <MenuItem value={6}>Двор 6</MenuItem>
                        <MenuItem value={7}>Двор 7</MenuItem>
                        <MenuItem value={8}>Двор 8</MenuItem>
                        <MenuItem value={9}>Двор 9</MenuItem>
                        <MenuItem value={10}>Двор 10</MenuItem>
                    </Select>
                </FormControl>
                <Grid container spacing={1}>
                    <Grid container item xs={12} sm={8} md={6} lg={6} xl={6}>
                        <TextField
                            error={createdLicencePlate.length === 0}
                            required
                            fullWidth
                            name="phoneNumber"
                            label="Номер телефона для связи"
                            InputProps={{
                                inputComponent: PhoneNumberMask,
                                value: createdLicencePlateOwnerPhone,
                                error: createdLicencePlateOwnerPhone.length === 0,
                                inputProps: { onChangePhoneNumber: (v: string) => {
                                        setCreatedLicencePlateOwnerPhone(v);
                                    }
                                },
                            }}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={4} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            required
                            name="licencePlate"
                            label="Имя"
                            value={createdLicencePlateOwner}
                            error={createdLicencePlateOwner.length === 0}
                            onChange={(e)=>{
                                setCreatedLicencePlateOwner(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
            {
                props.isDeleted &&
                <IconButton aria-label="delete" edge={"end"} onClick={onRemove}><DeleteIcon/></IconButton>
            }
        </FormControl>
    )
}