import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Alert,
    Button, CardMedia,
    Checkbox,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton, Radio, RadioGroup,
    Select, Step, StepContent, StepLabel, Stepper
} from "@mui/material";
import LicencePlateInput from "./LicencePlateInput";
import {useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from "./PrivacyPolicy";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export interface ILicencePlateInput {
    licencePlate?: string
    licencePlateCountry?: string
    licencePlateArea?: number[]
    licencePlateOwner?: string
    licencePlateOwnerPhone ?: string
}

export default function FormPage() {
    const [licencePlatesList, setLicencePlatesList] = useState<ILicencePlateInput[]>([{licencePlate: undefined, licencePlateCountry: 'rus', licencePlateArea: []}])

    const [isInvalidNestedForm, setInvalidNestedForm] = useState(false);
    const [isInvalidForm, setInvalidForm] = useState(false);


    const [isOpenRecommendations, setOpenRecommendations] = useState(false);
    const [isOpenHowItWorks, setOpenHowItWorks] = useState(false);

    const [isOpenTermsOfUse, setOpenTermsOfUse] = useState(false);
    const [isOpenPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);

    const [isAcceptTermsAndPrivacy, setAcceptTermsAndPrivacy] = useState(false);

    const [createdApartmentNumber, setCratedApartmentNumber] = useState<string>('');
    const [createdUnlivedNumber, setCratedUnlivedNumber] = useState<string>('');

    const [createType, setCreateType] = useState<string>('0');

    const [isSending, setSending] = useState(false);

    useEffect(()=>{
        if (createType == '0'){
            if (createdApartmentNumber.length === 0){
                setInvalidForm(true);
            } else {
                setInvalidForm(false);
            }
        } else {
            if (createdUnlivedNumber.length === 0){
                setInvalidForm(true);
            } else {
                setInvalidForm(false);
            }
        }
    }, [createdApartmentNumber, createdUnlivedNumber, createType]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(licencePlatesList)
        setSending(true);
        fetch('//api.sun.megaqr.ru/statement', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({apartmentNumber: createType == '0' ? createdApartmentNumber: `900${createdUnlivedNumber}`, licencePlatesList: licencePlatesList.map((value) => {value.licencePlateArea?.push(11,12); return value;})}),
            credentials: "include"
        })
            .then(async response => {
                console.log(response)
                setSending(false);
                if (response.ok){
                    const b = await response.json()
                    window.location.href = `statement/${b.id}`
                }


            })
            .catch(error => {

                console.error('There was an error!', error);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/background.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 2,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >

                        <Alert severity="success">
                            На территории <b>ЖК Город Cолнца</b> введена в эксплуатацию система автоматического доступа на придомовую территорию по госномеру автомобиля.
                            Для актуализации данных о транспортных средствах просим заполнить форму ниже.
                        </Alert>

                        <Alert severity="warning" sx={{ mt: 1}} onClick={()=>{
                            setOpenRecommendations(true);
                        }}>
                            <u>Рекомендации по заполнению акнкеты</u>
                        </Alert>

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={createType}
                                onChange={(e)=>{
                                    setCreateType(e.target.value)
                                }}
                            >
                                <FormControlLabel value="0" control={<Radio />} label="Квартира" />
                                <FormControlLabel value="1" control={<Radio />} label="Нежилое помещение" />
                            </RadioGroup>

                            {
                                createType == '0' ?
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        error={createdApartmentNumber.length === 0}
                                        value={createdApartmentNumber}
                                        onChange={(event) => {
                                            setCratedApartmentNumber(event.target.value)
                                        }}
                                        label="Номер квартиры"
                                        name="apartment"
                                    />
                                    :
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        error={createdUnlivedNumber.length === 0}
                                        value={createdUnlivedNumber}
                                        onChange={(event) => {
                                            setCratedUnlivedNumber(event.target.value)
                                        }}
                                        label="Номер помещения (без буквы Н)"
                                        name="unlived"
                                    />
                            }


                            <Typography variant="h6">
                                Номерные знаки
                            </Typography>
                            {licencePlatesList.map((r, i)=>(
                                <LicencePlateInput
                                    onRemove={()=>{
                                        const curList = [...licencePlatesList];
                                        curList.splice(i,1);
                                        setLicencePlatesList(curList);
                                    }}
                                    onLicencePlateCountryChange={(v: string)=>{
                                       licencePlatesList[i].licencePlateCountry = v;
                                       setLicencePlatesList(licencePlatesList);
                                    }}
                                    onLicencePlateChange={(v:string)=>{
                                       licencePlatesList[i].licencePlate = v;
                                       setLicencePlatesList(licencePlatesList);
                                    }}
                                    onLicencePlateAreaChange={(v:number[])=>{
                                        licencePlatesList[i].licencePlateArea = v;
                                        setLicencePlatesList(licencePlatesList);
                                    }}
                                    onLicencePlatePhoneChange={(v:string)=>{
                                        licencePlatesList[i].licencePlateOwnerPhone = v;
                                        setLicencePlatesList(licencePlatesList);
                                    }}
                                    onLicencePlateOwnerChange={(v:string)=>{
                                        licencePlatesList[i].licencePlateOwner = v;
                                        setLicencePlatesList(licencePlatesList);
                                    }}
                                    onInvalid={(v: boolean)=>{
                                        setInvalidNestedForm(v);
                                    }}
                                    isDeleted={licencePlatesList.length > 1}
                                    licencePlate={r.licencePlate}
                                    licencePlateCountry={r.licencePlateCountry}
                                    licencePlateArea={r.licencePlateArea}/>
                            ))}

                            <Button fullWidth color="primary" onClick={()=>{
                                setLicencePlatesList([...licencePlatesList, {licencePlate: undefined, licencePlateCountry: 'rus', licencePlateArea: []}])
                            }}>Хочу добавить еще один номерной знак</Button>



                        <FormControlLabel
                            style={{ pointerEvents: "none" }}
                            sx={{ mt: 2}}
                            control={
                                <Checkbox style={{pointerEvents: "auto"}} onChange={(e)=>{
                                    setAcceptTermsAndPrivacy(e.target.checked)
                                }}/>
                            }
                            label={
                                <Typography>
                                    Я соглашаюсь с <Link onClick={()=>{setOpenTermsOfUse(true)}} style={{cursor: "pointer", pointerEvents: "auto"}}>Условиями использования</Link> и <Link onClick={()=>{setOpenPrivacyPolicy(true)}} style={{cursor: "pointer", pointerEvents: "auto"}}>Политикой конфиденциальности</Link>
                                </Typography>
                            }
                        />
                        <Button sx={{ mt: 2}} variant="contained" disabled={!isAcceptTermsAndPrivacy || isInvalidForm || isInvalidNestedForm || isSending} type="submit" fullWidth>Отправить заявку</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={isOpenRecommendations}
                onClose={()=>{setOpenRecommendations(false)}}
            >
                <DialogTitle sx={{ my: 2, p: 2 }}>
                        <IconButton
                            aria-label="close"
                            onClick={()=>{setOpenRecommendations(false)}}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        <Stepper orientation="vertical" activeStep={-1}>
                            <Step>
                                <StepLabel>
                                    <Typography variant={"h6"}>
                                        Ознакомтесь с <Link onClick={()=>{setOpenTermsOfUse(true)}} style={{cursor: "pointer", pointerEvents: "auto"}}>Условиями использования</Link> и <Link onClick={()=>{setOpenPrivacyPolicy(true)}} style={{cursor: "pointer", pointerEvents: "auto"}}>Политикой конфиденциальности</Link>
                                    </Typography>
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel
                                    optional={
                                        <Typography>Номерной знак следует заполнять <b>латинскими буквами</b> (если номер введен корректно - рамка поля меняет цвет с красного на синий). Пример правильно заполненного поля приведен ниже. Для номерных знаков отличных от Российской Федерации в поле "Страна" следует выбрать нужную страну.
                                            <CardMedia
                                                component="img"
                                                image="/examples/ex-1.png"
                                                alt="Пример заполнения поля с номерным знаком"
                                            />
                                            При необходимости выберите доступ в несколько дворов (<b>однако, при модерации заявления список доступа может быть изменен правлением ТСЖ</b>).</Typography>
                                    }>
                                    <Typography variant={"h6"} gutterBottom>
                                        Заполните форму
                                    </Typography>
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>
                                    <Typography variant={"h6"}>
                                        Отправьте заявление на рассмотрение
                                    </Typography>
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>
                                    <Typography variant={"h6"}>
                                        Отслеживайте статус заявление по полученной ссылке
                                    </Typography>
                                </StepLabel>
                            </Step>
                        </Stepper>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={isOpenTermsOfUse}
                onClose={()=>{setOpenTermsOfUse(false)}}>
                <DialogTitle sx={{ my: 2, p: 1 }}>
                    <Typography variant="h5"  component="div">
                        Условия использования
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={()=>{setOpenTermsOfUse(false)}}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TermsOfUse/>
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={isOpenPrivacyPolicy}
                onClose={()=>{setOpenPrivacyPolicy(false)}}>
                <DialogTitle sx={{ my: 2, p: 1 }}>
                    <Typography variant="h5"  component="div">
                        Политика конфеденциальности
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={()=>{setOpenPrivacyPolicy(false)}}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <PrivacyPolicy/>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
}
