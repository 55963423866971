import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Alert, AlertColor, AlertTitle, Snackbar} from "@mui/material";
import Link from "@mui/material/Link";

const theme = createTheme();

export interface IrInfoStatementStatuses {
    at: Date;
    status: string;
    statusCode: number;
}

export interface IrInfoStatementLicencePlate {
    licencePlate: string;
    status: string;
    statusCode: number;
    licencePlateAreas: IrInfoStatementLicencePlateArea[];
}

export interface IrInfoStatementLicencePlateArea {
    area: number;
    areaDescription: string;
}

function getSeverityType(status: number): AlertColor {
    const r = {
        1: 'info',
        2: 'warning',
        3: 'success',
        4: 'error',
        default: 'error'
    } as Record<number | string, AlertColor>
    return r[status] || r.default;
}

export default function StatementPage() {
    let { statementId } = useParams();

    const [licencePlates, setLicencePlates] = useState<IrInfoStatementLicencePlate[]>([]);
    const [statuses, setStatuses] = useState<IrInfoStatementStatuses[]>([]);

    const [isOpenSuccessCopy, setOpenSuccessCopy] = useState<boolean>(false);


    useEffect(()=>{
        fetch(`//api.sun.megaqr.ru/statement/${statementId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: "include"
        })
            .then(async response => {

                if (response.ok){
                    const res = await response.json();
                    setStatuses(res.statuses)
                    setLicencePlates(res.licencePlates)
                }

            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [statementId]);

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/background.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 2,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="h6">
                            Статусы заявления
                        </Typography>
                        {
                            statuses.map((value, index) => (
                                <Alert key={`alert-${index}`} severity={getSeverityType(value.statusCode)} action={<strong>{new Date(value.at).toLocaleString()}</strong>} sx={{my: 1}}>
                                    <AlertTitle>{value.status}</AlertTitle>
                                    {/*{l.barrier} - <strong>{getTypeText(l.status)}</strong> {l.status === 3 && l.blacklistReason && <>Причина блокировки: <strong>{l.blacklistReason}.</strong></>}*/}
                                </Alert>

                            ))
                        }

                        <Typography variant="h6">
                            Номерные знаки
                        </Typography>
                        {
                            licencePlates.map((value, index) => (
                                <Alert key={`alert-${index}`} severity={getSeverityType(value.statusCode)} action={value.status} sx={{my: 1}}>
                                    <AlertTitle><strong>{value.licencePlate}</strong></AlertTitle>
                                    <>Доступ на территории:</>
                                    <ul>
                                        {value.licencePlateAreas.map((value1) => (
                                            <li>{value1.areaDescription}</li>
                                        ))}
                                    </ul>

                                </Alert>

                            ))
                        }

                        <Typography variant="subtitle1">
                            Вы можете отслеживать статус заявления находясь на этой странице. Чтобы скопировать ссылку на страницу <Link onClick={()=>{navigator.clipboard.writeText(`https://sun.megaqr.ru/statement/${statementId}`); setOpenSuccessCopy(true);}} style={{cursor: "pointer", pointerEvents: "auto"}}>нажмите здесь.</Link>
                        </Typography>

                        <Snackbar open={isOpenSuccessCopy} autoHideDuration={1000} onClose={()=>{setOpenSuccessCopy(false)}} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert onClose={()=>{setOpenSuccessCopy(false)}} severity="success" sx={{ width: '100%' }}>
                                Ссылка скопирована в буфер обмена
                            </Alert>
                        </Snackbar>
                        </Box>

                </Grid>
            </Grid>

        </ThemeProvider>
    );
}