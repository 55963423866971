import React from "react";
import {Typography} from "@mui/material";

export default function PrivacyPolicy() {
    return (
        <React.Fragment>
            <Typography variant="h5" gutterBottom component="div">
                1. ОБЩИЕ ПОЛОЖЕНИЯ
            </Typography>
            <Typography variant="body1" gutterBottom>
                1.1. Настоящая политика конфиденциальности (далее – Политика) составлена в соответствии с требованиями Федерального закона от 27.07.200 №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые обществом с ограниченной ответственностью «ИНТЕРПЭЙ», ИНН 7840044292, ОГРН 1157847442158 (далее – Оператор).
            </Typography>
            <Typography variant="body1" gutterBottom>
                1.2. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
            </Typography>
            <Typography variant="body1" gutterBottom>
                1.3. Настоящая политика конфиденциальности (политика в отношении обработки персональных данных) Оператора (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://sun.megaqr.ru/.
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                2. ОСНОВНЫЕ ПОНЯТИЯ, ИСПОЛЬЗУЕМЫЕ В ПОЛИТИКЕ
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://sun.megaqr.ru/;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.7. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://sun.megaqr.ru/;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.8. Пользователь – любой посетитель веб-сайта https://sun.megaqr.ru/;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.9. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.10. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.11. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;
            </Typography>
            <Typography variant="body1" gutterBottom>
                2.12. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                3. ОПЕРАТОР МОЖЕТ ОБРАБАТЫВАТЬ СЛЕДУЮЩИЕ ПЕРСОНАЛЬНЫЕ ДАННЫЕ ПОЛЬЗОВАТЕЛЯ
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.1. Имя владельца транспортного средства;
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.2. Государственный регистрационный номер транспортного средства;
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.3. Номер двора проживания владельца транспортного средства;
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.4. Номер квартиры проживания владельца транспортного средства;
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.5. Номер телефона владельца транспортного средства;
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.6. Иные данные о Пользователе, предоставленные Пользователем либо третьими лицами на законных основаниях.
            </Typography>
            <Typography variant="body1" gutterBottom>
                3.7. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                4. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
            </Typography>
            <Typography variant="body1" gutterBottom>
                4.1. Цель обработки персональных данных Пользователя – предоставление доступа Пользователю к сервисам, функционалу, информации и/или материалам, содержащимся на веб-сайте, а также организация работы системы контроля доступа для парковки, помещений (подъезды жилых домов), на придомовую территорию Товарищества Собственников Жилья «Город Солнца».
            </Typography>
            <Typography variant="body1" gutterBottom>
                4.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах, функциях и услугах, различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты info@sun.megaqr.ru с пометкой «Отказ от уведомлений о новых продуктах, функциях и услугах, различных событиях».
            </Typography>
            <Typography variant="body1" gutterBottom>
                4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                5. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
            </Typography>
            <Typography variant="body1" gutterBottom>
                5.1. Правовыми основаниями обработки персональных данных Пользователя являются: Гражданский Кодекс РФ, Федеральный закон от 27.07.2006. №152–ФЗ «О персональных данных», Федеральный закон РФ от 27.07.2006 № 149-ФЗ «Об информации, информационных технологиях и о защите информации», Постановление Правительства РФ от 01.11.2012 № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных», Постановление Правительства РФ от 15.09.2008 № 687 «Об утверждении положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации», Приказ ФСТЭК России от 18.02.2013 № 21 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных», иные применимые нормативно-правовые акты, локальные правовые акты Оператора, Согласие Пользователя с Политикой конфиденциальности.
            </Typography>
            <Typography variant="body1" gutterBottom>
                5.2. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте https://sun.megaqr.ru/. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                6. ПОРЯДОК СБОРА, ХРАНЕНИЯ, ПЕРЕДАЧИ И ДРУГИХ ВИДОВ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.1. Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.2. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.3. Обработка персональных данных осуществляется как автоматическим, так и автоматизированным способом.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.4. При обработке персональных данных, осуществляемой без использования средств автоматизации, Оператор обязуется соблюдать требования о раздельном хранении персональных данных (материальных носителей), обработка которых осуществляется в различных целях, определении мест хранения персональных данных и лиц, имеющих доступ к персональным данным, и иные требования, установленные законодательством Российской Федерации к обработке персональных данных, осуществляемой без использования средств автоматизации.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.5. При обработке персональных данных автоматическим и автоматизированным способом Оператор обязан своевременно производить оценку соответствия требованиям законодательства Российской Федерации в области защиты информации и соблюдать иные требования к автоматизированной обработке персональных данных.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.6. При обработке персональных данных Оператор не осуществляет трансграничной передачи персональных данных.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.7. Оператор обязан соблюдать законные права Пользователей в сфере обработки персональных данных, в том числе содействовать реализации прав Пользователя, указанных в Политике, а также соблюдать иные требования к обработке персональных данных, установленные законодательством Российской Федерации.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.8. В целях соблюдения законодательства РФ, для достижения целей обработки персональных данных, а также в интересах субъектов персональных данных Оператор в ходе своей деятельности предоставляет персональные данные следующим третьим лицам Пользователя:
                <ul>
                    <li>Товариществу Собственников Жилья «Город Солнца», ИНН 7802195950, ОГРН 1027801552745 – для организации работы системы контроля доступа для парковки, помещений (подъезды жилых домов), на придомовую территорию Товарищества Собственников Жилья «Город Солнца»;</li>
                    <li>иным лицам в случаях, если передача необходима в рамках установленной законодательством Российской Федерации процедуры.</li>
                </ul>
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.9. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомления посредством электронной почты на электронный адрес Оператора info@sun.megaqr.ru с пометкой «Актуализация персональных данных» либо через личный кабинет Пользователя на Веб-сайте (в случае наличия в личном кабинете Пользователя соответствующего функционала).
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.10. Оператор обрабатывает только те персональные данные, которые необходимы для предоставления сервисов сайта https://sun.megaqr.ru/ или исполнения соглашений с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональных данных в течение определенного законом срока
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.11. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора info@sun.megaqr.ru с пометкой «Отзыв согласия на обработку персональных данных».
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.12. Оператор не проверяет достоверность персональных данных, предоставляемых Пользователем, не имеет возможности оценивать его дееспособность. Однако Оператор исходит из того, что Пользователь действует добросовестно, осмотрительно, предоставляет достоверные и достаточные персональные данные и прилагает все необходимые усилия к поддержке таких данных в актуальном состоянии, и не нарушает права третьих лиц.
            </Typography>
            <Typography variant="body1" gutterBottom>
                6.13. Соглашаясь с условиями настоящей Политики, Пользователь подтверждает (гарантирует), что персональные данные, которые переданы на обработку Оператору, переданы с согласия владельцев персональных данных и в рамках законодательства Российской Федерации. Оператор, получив персональные данные от Пользователя, не принимает на себя обязательства по информированию субъектов (их представителей), персональные данные которых переданы Оператору, о начале обработки персональных данных, поскольку обязанность осуществить соответствующее информирование, при заключении сделки с субъектом персональных данных и/или при получении согласия на такую передачу, несет Пользователь, который передал персональные данные.
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                7. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЕЙ ПРИ ОБРАБОТКЕ ИХ ПЕРСОНАЛЬНЫХ ДАННЫХ
            </Typography>
            <Typography variant="body1" gutterBottom>
                7.1. Пользователь имеет право:
                <ul>
                    <li>использовать свои персональные данные в любых законных целях, в том числе в целях, установленных Политикой;</li>
                    <li>направлять Оператору запросы на получение информации, касающейся обработки его персональных данных;</li>
                    <li>оспаривать и обжаловать действия Оператора в сфере обработки персональных данных в порядке, установленном законодательством Российской Федерации;</li>
                    <li>направлять свои предложения, вопросы и жалобы посредством электронной почты на электронный адрес Оператора info@sun.megaqr.ru и в иной форме, допустимой законодательством Российской Федерации;</li>
                    <li>в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора info@sun.megaqr.ru с пометкой «Отзыв согласия на обработку персональных данных»;</li>
                    <li>потребовать прекращения обработки своих персональных данных посредством электронной почты на электронный адрес Оператора info@sun.megaqr.ru с пометкой «Прекращение обработки персональных данных»;</li>
                    <li>реализовывать иные права, предоставленные ему законодательством Российской Федерации в сфере персональных данных.</li>
                </ul>
            </Typography>
            <Typography variant="body1" gutterBottom>
                7.2. Пользователь обязан:
                <ul>
                    <li>самостоятельно обеспечивать сохранность своего пароля от личного кабинета Пользователя на Веб-сайте и нести ответственность за негативные последствия в виде неправомерного использования своих персональных данных третьими лицами, наступившие в результате небрежного отношения Пользователя к своему паролю от личного кабинета;</li>
                    <li>обновлять обрабатываемые персональные данные в случае их изменения, если автоматическое обновление указанных данных невозможно;</li>
                    <li>осуществлять иные обязанности в сфере персональных данных, когда такие обязанности возникают у субъекта персональных данных в ходе обработки его персональных данных в целях, предусмотренной Политикой.</li>
                </ul>
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
            </Typography>
            <Typography variant="body1" gutterBottom>
                8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору посредством электронной почты на электронный адрес Оператора info@sun.megaqr.ru
            </Typography>
            <Typography variant="body1" gutterBottom>
                8.2. Политика действует бессрочно до замены ее новой версией.
            </Typography>
            <Typography variant="body1" gutterBottom>
                8.3. Оператор вправе вносить изменения в Политику без согласия Пользователя.
            </Typography>
            <Typography variant="body1" gutterBottom>
                8.4. Новая Политика вступает в силу с момента ее размещения на Веб-сайте, если иное не предусмотрено новой редакцией Политики.
            </Typography>
        </React.Fragment>
    )
}
