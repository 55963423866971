import {IMaskInput} from "react-imask";
import React, {useEffect, useState} from "react";

const cyrillicToLatin = new Map([
    ['А', 'A'],
    ['В', 'B'],
    ['Е', 'E'],
    ['К', 'K'],
    ['М', 'M'],
    ['Н', 'H'],
    ['О', 'O'],
    ['Р', 'P'],
    ['С', 'C'],
    ['Т', 'T'],
    ['У', 'Y'],
    ['Х', 'X'],
])

export const cyrillicToLatinAutoConvert = (str: string) => {
    return str.toUpperCase().split('').map((s)=> cyrillicToLatin.has(s) ? cyrillicToLatin.get(s): s).join('');
}


export default function LicencePlateMask(props: any) {
    const { inputRef, onChangeLicencePlate, licencePlateCountry, ...other } = props;
    const [currentMask, setCurrentMask] = useState({})

    useEffect(()=>{
        switch (licencePlateCountry) {
            case 'rus':
                setCurrentMask([
                    {
                        mask: 'S DDD SS DD[D]',
                        definitions: {
                            S: /[ABEKMHOPCTYX]/,
                            D: /[0-9]/,
                        },
                        prepare: cyrillicToLatinAutoConvert,
                    }])
                break;
            case 'lv':
                setCurrentMask([
                    {
                        mask: 'SS{-}D[DDD]',
                        definitions: {
                            S: /[A-Z]/,
                            D: /[0-9]/,
                        },
                        prepare: cyrillicToLatinAutoConvert,
                    }])
                break;
            case 'fin':
                setCurrentMask([
                    {
                        mask: 'SS[S]{-}DD[D]',
                        definitions: {
                            S: /[A-Z]/,
                            D: /[0-9]/,
                        },
                        prepare: cyrillicToLatinAutoConvert,
                    }])
                break;
            case 'ua':
                setCurrentMask([
                    {
                        mask: 'SS DDDD SS',
                        definitions: {
                            S: /[A-Z]/,
                            D: /[0-9]/,
                        },
                        prepare: cyrillicToLatinAutoConvert,
                    }
                ])
                break;
            case 'kz':
                setCurrentMask([
                    {
                        mask: 'DDD SS[S] DD',
                        definitions: {
                            S: /[A-Z]/,
                            D: /[0-9]/,
                        },
                        prepare: cyrillicToLatinAutoConvert,
                    }
                ])
                break;
            case 'uz':
                setCurrentMask([
                    {
                        mask: 'DD SDDDSS',
                        definitions: {
                            S: /[A-H,J-Z]/,
                            D: /[0-9]/,
                        },
                        prepare: cyrillicToLatinAutoConvert,
                    }
                ])
                break;
            case 'kg':
                setCurrentMask([
                    {
                        mask: 'DD DDD SS[S]',
                        definitions: {
                            S: /[A-Z]/,
                            D: /[0-9]/,
                        },
                        prepare: cyrillicToLatinAutoConvert,
                    }
                ])
                break;
            case 'by':
                setCurrentMask([
                    {
                        mask: 'DDDD SS{-}D',
                        definitions: {
                            S: /[ABEIKMNOPCTX]/,
                            D: /[0-9]/,
                        },
                        prepare: cyrillicToLatinAutoConvert,
                    },
                    {
                        mask: 'SS DDDD{-}D',
                        definitions: {
                            S: /[ABEIKMNOPCTX]/,
                            D: /[0-9]/,
                        },
                        prepare: cyrillicToLatinAutoConvert,
                    }])
                break;
            default:
                setCurrentMask({})
        }
        onChangeLicencePlate('')
    }, [licencePlateCountry])

    return (
        <IMaskInput
            {...other}
            mask={currentMask}
            unmask={true}
            inputRef={inputRef}
            onComplete={
                (value: string, mask: string) => {
                    onChangeLicencePlate(value)
                }
            }
            placeholder='Вводите номерной знак латинскими буквами'
        />
    );
}
