import React from "react";
import {DialogContentText, Typography} from "@mui/material";

export default function TermsOfUse() {

    return (
        <React.Fragment>
            <DialogContentText>
                <Typography variant="h5" gutterBottom component="div">
                    1. Общие положения
                </Typography>
                <Typography variant="body1" gutterBottom>
                    1.1. Настоящие Условия использования регулируют отношения пользователей веб-сайта https://sun.megaqr.ru/ (далее – Сайт) и системы контроля доступа для парковки, помещений (подъезды жилых домов), на придомовую территорию Товарищества Собственников Жилья «Город Солнца» (далее – СКД) с обществом с ограниченной ответственностью «ИНТЕРПЭЙ», ИНН 7840044292, ОГРН 1157847442158 (далее – Оператор).
                </Typography>
                <Typography variant="body1" gutterBottom>
                    1.2. Пользователем является любое физическое лицо или сотрудник юридического лица, зарегистрированные на Сайте.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    1.3. Используя Сайт и СКД, Вы соглашаетесь с настоящими Условиями использования. Если Вы не согласны с настоящими Условиями использования, не используйте Сайт и СКД.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    1.4. Внося данные на Сайт, пользователь просит внести такие данные в СКД и разрешить въезд на придомовую территорию Товарищества Собственников Жилья «Город Солнца» транспортных средств согласно действующих квот. Пользователь несет полную ответственность за результаты нахождения на придомовой территории транспортных средств, данные о которых внесены пользователем.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    1.5. В случае, если пользователь предоставляет в аренду свое машино-место, Пользователь обязуется вносить на Сайте данные об арендаторе машино-места и транспортном средстве арендатора.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    1.6. Внося данные на Сайт, пользователь подтверждается, что он уведомлен и уведомил всех арендаторов своих машино-мест о том, что в соответствии с п. 1 ч. 2 ст. 1, ч. 1 ст. 6, пп. 1 п. 1 ст. 90 Федерального закона № 123-Ф3 «Технический регламент о требованиях пожарной безопасности», стоянка автомобилей в местах предназначенных для проезда пожарной техники запрещена и, в случае нарушения правил размещения транспортного средства во дворах (парковка на поворотах, парковка напротив парадных и др.), доступ транспортного средства во двор будет ограничен. Доступ будет возможен только к парадной для разгрузки/погрузки на период до 30 мин.
                </Typography>
                <Typography variant="h5" gutterBottom component="div">
                    2. Регистрация пользователей на Сайте
                </Typography>
                <Typography variant="body1" gutterBottom>
                    2.1. Любой пользователь сети Интернет, регистрация которого предварительно одобрена Товариществом Собственников Жилья «Город Солнца», может бесплатно зарегистрировать учётную запись пользователя Сайта.
                </Typography>
                <Typography variant="h5" gutterBottom component="div">
                    3. Права и обязанности пользователя
                </Typography>
                <Typography variant="body1" gutterBottom>
                    3.1. Пользователь вправе:
                    <ul>
                        <li>использовать свою учётную запись на Сайте для санкционированного доступа к информации и редактирования информации, содержащейся на Сайте и в базе данных СКД;</li>
                        <li>удалить свою учётную запись.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    3.2. Пользователь обязан:
                    <ul>
                        <li>не производить действия, направленные на нарушение процесса функционирования Сайта или СКД;</li>
                        <li>соблюдать требования «Политики конфиденциальности».</li>
                    </ul>
                </Typography>
                <Typography variant="h5" gutterBottom component="div">
                    4. Права и обязанности оператора
                </Typography>
                <Typography variant="body1" gutterBottom>
                    4.1. Оператор вправе:
                    <ul>
                        <li>требовать от пользователя подтверждения основания получения доступа к Сайту и СКД;</li>
                        <li>проводить мониторинг действий пользователя на Сайте;</li>
                        <li>ограничивать доступ пользователя к Сайту и/или СКД, в том числе путем блокировки учетной записи пользователя;</li>
                        <li>изменить настоящие Условия использования без предварительного уведомления пользователя;</li>
                        <li>направлять пользователю информационные сообщения.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    4.2. Оператор обязан:
                    <ul>
                        <li>обеспечить функционирование Сайта и СКД;</li>
                        <li>гарантировать целостность и неизменность данных пользователя;</li>
                        <li>обеспечить защиту персональных данных пользователя в соответствии с требованиями законодательства РФ и «Политики конфиденциальности».</li>
                    </ul>
                </Typography>
                <Typography variant="h5" gutterBottom component="div">
                    5. Обратная связь
                </Typography>
                <Typography variant="body1" gutterBottom>
                    5.1. Все предложения и вопросы по использованию Сайта и СКД следует направлять Оператору посредством электронной почты на электронный адрес Оператора info@sun.megaqr.ru
                </Typography>
            </DialogContentText>
        </React.Fragment>
        )
}
